<template>
    <cluji-card no-body :no-header="isCollapseShown"
                :subtitle="formatDates(evenement)"
                @collapse="updateCollapse">
        <template #title>
            <router-link :to="linkToDetail">
                {{ evenement.nom }}
            </router-link>
        </template>
        <div class="limit-height">
            <b-card-img-lazy :src="image" class="evenement-image mr-4" block/>
            <div class="p-4 mt-auto mb-auto evenement-infos">
                <h2>
                    <router-link :to="linkToDetail">
                        {{ evenement.nom }}
                    </router-link>
                </h2>
                <p><em>{{ formatDates(evenement) }}</em></p>
                <p>{{ evenement.resume }}</p>
            </div>
        </div>
    </cluji-card>
</template>

<script>
    const ClujiCard = () => import('@/components/ClujiCard');

    import {thumbnailSrc} from '@/util/image';
    import {formatDates}  from '@/util/demande';
    import {linkToDetail} from '@/util/evenement';

    export default {
        name: "EvenementCardPreview",
        components: {ClujiCard},
        props: {
            evenement: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            isCollapseShown: true
        }),
        methods: {
            formatDates,
            updateCollapse(val) {
                this.isCollapseShown = val;
            }
        },
        computed: {
            linkToDetail() {
                return linkToDetail(this.evenement, this.$route);
            },
            image() {
                return thumbnailSrc(this.evenement, 'evenements');
            }
        }
    }
</script>

<style scoped>
    .limit-height {
        max-height: 256px;
        display: flex;
    }

    /* Media queries to make the event vertical in small screens*/
    @media (max-width: 992px) {
        .limit-height {
            max-height: 512px;
            display: block;
        }
    }

    .evenement-image {
        object-fit: cover;
        max-width: 50%;
        max-height: 256px;
        width: auto;
        height: auto;
    }

    @media (max-width: 992px) {
        .evenement-image {
            width: 100%;
            max-width: 100%;
        }
    }
</style>
